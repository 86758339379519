import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import SingleFilterItem from "../components/SingleFilterItem"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import DatePicker from "react-date-picker"

const Direction = {
  Prev: "Prev",
  Current: "Current",
  Next: "Next",
}
let tooltipInstance = null
export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      workshops(locales: $locale) {
        title
        id
        description
        cardImage(locales: en) {
          url
        }
        start
        end
        slug
      }
      programmes(locales: $locale) {
        title
        id
        description
        cardImage(locales: en) {
          url
        }
        start
        end
        slug
      }
    }
  }
`
class Calendar extends React.Component {
  calendarComponentRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      calendarApi: "",
      title: "",
      currentDate: new Date(),
      allEvents: this.adjustUrls(),
      currentEvents: [],
      workshopsItems: this.props.data.gcms.workshops,
      programmesItems: this.props.data.gcms.programmes,
      dateFilterVisible: false,
    }

    this.wrapperRef1 = React.createRef()
    this.handleFilterChangeType = this.handleFilterChangeType.bind(this)
    this.toggleDateFilter = this.toggleDateFilter.bind(this)
    this.handleOnDateChange = this.handleOnDateChange.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  toggleDateFilter() {
    this.setState({
      dateFilterVisible: !this.state.dateFilterVisible,
    })
  }
  handleOnDateChange(date) {
    if (date) {
      // set date
      this.state.calendarApi.gotoDate(date)
      this.setState({
        currentDate: date,
        title: this.state.calendarApi.getCurrentData().viewTitle,
      })
    }
  }

  handleClickOutside = e => {
    if (
      this.wrapperRef1.current &&
      !this.wrapperRef1.current.contains(e.target)
    ) {
      this.setState({
        dateFilterVisible: false,
      })
    }
  }

  handleFilterChangeType(selectedCheckboxes) {
    let temp = []

    this.state.allEvents
      .filter(item => selectedCheckboxes.indexOf(item.type) > -1)
      .map(filteredItem => temp.push(filteredItem))

    if (temp.length < 1) {
      temp = this.state.allEvents
    }

    this.setState({
      currentEvents: temp,
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside, false)
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside, false)
    this.wrapperRef1.current.focus()
    this.setState({
      currentEvents: this.state.allEvents,
    })
  }
  adjustUrls() {
    var tempArr = []
    this.props.data.gcms.workshops.map((item, i) => {
      item.slug = "/workshops/" + item.slug
      item.type = "workshops"
      tempArr.push(item)
    })
    this.props.data.gcms.programmes.map((item, i) => {
      item.slug = "/programmes/" + item.slug
      item.type = "programmes"
      tempArr.push(item)
    })
    return tempArr
  }

  render() {
    const { t } = this.props
    return (
      <Layout>
        <div>
          <div className="font-futurastd_book flex-col lg:flex lg:flex-row lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <div className="col-span-3 text-center lg:col-span-1 row-span-1  uppercase w-full lg:w-auto lg:order-3">
              <div className="inline-block bg-white">
                <button
                  onClick={() => this.handleMonthViewClick(Direction.Prev)}
                  className="text-2xl"
                >
                  &lt;
                </button>
                <button
                  onClick={() => this.handleMonthViewClick(Direction.Current)}
                  className="tracking-title-3 text-base ml-3 mr-2 uppercase"
                >
                  {/* Title is the formated event of the date */}
                  {this.state.title}
                </button>
                <button
                  onClick={() => this.handleMonthViewClick(Direction.Next)}
                  className="text-2xl"
                >
                  &gt;
                </button>
              </div>
            </div>

            <div className="bg-white pr-2 lg:order-first z-10">
              <div className="bg-title-double-slash bg-left bg-no-repeat pl-8 leading-7">
                <button
                  onClick={() => this.toggleDateFilter()}
                  className={`${
                    this.state.dateFilterVisible
                      ? `font-futurastd_bold`
                      : `font-futurastd_book`
                  } bg-white text-label-fs-10 uppercase tracking-title-2`}
                >
                  {t("filter_filter_by_date")}
                </button>
              </div>
              <div
                ref={this.wrapperRef1}
                className={`${
                  this.state.dateFilterVisible
                    ? `lg:absolute lg:block whitespace-nowrap`
                    : `hidden`
                } lg:top-30 lg:bg-white lg:border-black lg:border-dashed lg:border p-2`}
              >
                <DatePicker
                  value={this.state.currentDate}
                  onChange={e => {
                    this.handleOnDateChange(e)
                  }}
                />
              </div>
            </div>

            <div className="lg:order-3 ">
              <SingleFilterItem
                onFilterChangeType={this.handleFilterChangeType}
              />
            </div>
          </div>
        </div>
        <div className="demo-app-main font-futurastd_book text-nav-header uppercase tracking-nav-header">
          <FullCalendar
            plugins={[dayGridPlugin]}
            headerToolbar={{
              left: "",
              center: "",
              right: "",
            }}
            initialView="dayGridMonth"
            height={1050}
            locale={this.props.language}
            direction={this.props.language === "en" ? "ltr" : "rtl"}
            dayMaxEvents={true}
            eventTimeFormat={{
              hour: "numeric",
              meridiem: "short",
            }}
            events={this.state.currentEvents}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            ref={this.calendarComponentRef}
            viewDidMount={data => {
              this.setState({
                title: data.view.getCurrentData().viewTitle,
                calendarApi: data.view.getCurrentData().calendarApi,
              })
            }}
          />
        </div>
      </Layout>
    )
  }

  handleMonthViewClick(direction) {
    let calendarApi = this.state.calendarApi
    switch (direction) {
      case Direction.Next:
        calendarApi.next()
        break
      case Direction.Prev:
        calendarApi.prev()
        break
      case Direction.Current:
        calendarApi.today()
        break

      default:
        break
    }

    this.setState({
      currentDate: calendarApi.getDate(),
      title: calendarApi.getCurrentData().viewTitle,
    })
  }

  handleEventClick = clickInfo => {
    window.open(clickInfo.event.extendedProps.slug)
  }
  handleMouseEnter = info => {
    tooltipInstance = new Tooltip(info.el, {
      title: info.event.extendedProps.description,
      html: true,
      placement: "top",
      trigger: "hover",
      container: "body",
    })

    tooltipInstance.show()
  }
  handleMouseLeave = info => {
    console.log(info)
    if (tooltipInstance) {
      tooltipInstance.dispose()
      tooltipInstance = null
    }
  }
}

function renderEventContent(eventInfo) {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Link to={eventInfo.event.extendedProps.slug}>
              <img src={eventInfo.event.extendedProps.cardImage.url} />
              <div className=" p-5">
                <div className="">
                  <b>
                    {eventInfo.timeText}: {eventInfo.event.title}
                  </b>
                  <br></br>
                  <b>
                    {eventInfo.event.start.toLocaleDateString(
                      eventInfo.event.source.context.dateEnv.locale.codeArg,
                      {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </b>
                </div>
                <p className=" mt-3">
                  {eventInfo.event.extendedProps.description}
                </p>
              </div>
            </Link>
          </React.Fragment>
        }
      >
        <div className="text-cal inline-flex font-futurastd_medium">
          <p>
            <span className="font-futurastd_heavy">{eventInfo.timeText}</span>
            &nbsp;{eventInfo.event.title}
          </p>
        </div>
      </HtmlTooltip>
    </div>
  )
}
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    padding: 0,
    marginTop: 0,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2);",
  },
}))
export default withTrans(Calendar)
