import React from "react"
import { withTrans } from "../withTrans"

class SingleFilterItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCheckboxes: [],
      groupTypeVisible: false,
      filterTypes: ["workshops", "programmes"],
    }
    this.wrapperRef1 = React.createRef()
    this.handleChangeType = this.handleChangeType.bind(this)
    this.toggleTypeGroup = this.toggleTypeGroup.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  toggleTypeGroup() {
    this.setState({
      groupTypeVisible: !this.state.groupTypeVisible,
    })
  }

  handleClickOutside = e => {

    if (
      this.wrapperRef1.current &&
      !this.wrapperRef1.current.contains(e.target)
    ) {
      this.setState({
        groupTypeVisible: false,
      })
    }
  }
  handleChangeType(item) {
    const checkedBoxes = this.state.selectedCheckboxes
    if (checkedBoxes.includes(item)) {
      checkedBoxes.splice(checkedBoxes.indexOf(item), 1)
    } else {
      checkedBoxes.push(item)
    }

    this.setState(
      {
        selectedCheckboxes: checkedBoxes,
      },
      () => {
        this.props.onFilterChangeType(this.state.selectedCheckboxes)
      }
    )
  }
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside, false)
  }
  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside, false)
    this.wrapperRef1.current.focus()
  }
  render() {
    const { t } = this.props
    const { selectedCheckboxes } = this.state
    return (
      <>
        <div className="bg-white pr-2 bg-title-double-slash bg-left bg-no-repeat pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7">
          <button
            onClick={() => this.toggleTypeGroup()}
            className={`${
              this.state.groupTypeVisible
                ? `font-futurastd_bold`
                : `font-futurastd_book`
            } bg-white text-label-fs-10 uppercase tracking-title-2`}
          >
            {t("filter_filter_by_type")}
          </button>
        </div>
        <div
          ref={this.wrapperRef1}
          className={`${
            this.state.groupTypeVisible
              ? `lg:absolute lg:block overflow-auto hide-scrollbar whitespace-nowrap`
              : `hidden`
          } lg:top-30 lg:bg-white lg:border-black lg:border-dashed lg:border p-2 z-50`}
        >
          {this.state.filterTypes.map((item, i) => (
            <div
              className={`${
                selectedCheckboxes.includes(item)
                  ? `bg-black text-white `
                  : `bg-white text-black `
              } text-black mx-3 lg:bg-transparent border-black border lg:border-none inline-block lg:block`}
              key={i}
            >
              <input
                id={i + 10}
                className="hidden lg:inline"
                type="checkbox"
                onChange={() => {
                  this.handleChangeType(item)
                }}
                name={item}
                checked={selectedCheckboxes.includes(item)}
              />
              <label
                htmlFor={i + 10}
                className={`${
                  selectedCheckboxes.includes(item)
                    ? ` text-white lg:text-black`
                    : ` text-black`
                } p-2 font-futurastd_book uppercase text-label-fs-10`}
              >
                {t(item)}
              </label>
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default withTrans(SingleFilterItem)
